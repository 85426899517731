import _t4Templating from "./t4-templating.js";
import _vbnet from "./vbnet.js";
var exports = {};
var refractorT4Templating = _t4Templating;
var refractorVbnet = _vbnet;
exports = t4Vb;
t4Vb.displayName = "t4Vb";
t4Vb.aliases = [];

function t4Vb(Prism) {
  Prism.register(refractorT4Templating);
  Prism.register(refractorVbnet);
  Prism.languages["t4-vb"] = Prism.languages["t4-templating"].createT4("vbnet");
}

export default exports;